
*::-webkit-scrollbar{
    width: 7px;
}
*::-webkit-scrollbar-track{
    background: #f5f5f5;
}
*::-webkit-scrollbar-thumb{
    background-color: #e3e3e3;
    border-radius: 20px;
}
body::-webkit-scrollbar{
    width: 10px;
}
body::-webkit-slider-thumb{
    background-color: #e0e0e0;
    border-radius: 20px;
}
body::-webkit-slider-track{
    background: #f5f5f5;
}
.fade-enter{
        opacity: 0.01;
        
}
.fade-enter.fade-enter-active{
    opacity: 1;
    transition: opacity 500ms ease-in;
}
.fade-leave{
    opacity: 1;
}
.fade-leave.fade-leave-active{
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}